body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 1.25rem;
  margin: 0px;
}
ul {
  margin-top: 0.25rem;
}
li {
  font-size: 0.75rem;
}
p {
  font-size: 1rem;
  margin-bottom: 0rem;
}
label {
  font-size: 0.75rem;
}
input,
label,
button,
.btn,
.MuiFormLabel-root,
.MuiInputBase-root,
.MuiInputBase-input,
.MuiOutlinedInput-notchedOutline,
.MuiCheckbox-root,
.MuiSelect-icon {
  color: white !important;
}
button,
.btn,
.MuiFormLabel-root,
.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}
.Mui-focused .MuiFormLabel-root,
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(65, 131, 215, 1) !important;
  color: rgba()
}
.MuiFormControl-root {
  margin: 0.5rem !important;
}
.MuiFormLabel-root {
  text-align: left;
  margin-bottom: 0.25rem;
}
button,
.btn {
  /* margin: 1rem !important; */
  width: 4rem;
}
img {
  width: 100%;
  max-height: 400px;
}

/* Number input arrow hide */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

/* Reset Button */
#resetButton {
  margin-top: 1rem;
  width: 100%;
}

/* Send Button */
#sendButton {
  margin-top: 1rem;
  padding: 1rem 0rem;
  width: 100%;
}

/* PSU Toggle */
#psu-toggle {
  height: 100%;
  display: grid;
  width: 50%;
  margin: auto;
}

/* Arb File Upload */
.MuiFormControl-root.uploadBtn,
#uploadArb {
  width: 97%;
}

/* Dropdown */
.MuiSelect-outlined.MuiSelect-outlined {
  padding: 1.25rem 1.5rem 1.25rem 1rem !important;
  margin: 0rem 0.5rem;
  width: auto;
}

#relay-group .MuiFormGroup-root {
  width: 100%;
  flex-direction: row;
}
span.MuiTypography-body1 {
  font-size: 0.75rem;
  width: 4rem;
  text-align: left;
}

/* Logs */
#msgBoard {
  text-align: left;
  margin: 2rem;
}
#msgBoard p {
  font-size: 0.75rem;
}
#msgBoard #messages {
  padding-left: 0rem;
}
#msgBoard #messages li {
  text-align: left;
  list-style: none;
  font-size: 0.55rem;
}
